// src/components/Home.js
import React from 'react';
import Cards from './Cards'; // Certifique-se de que o caminho para Cards está correto
/*import Banner from './Banner';*/

function Home() {
  return (
    <div>
      
      <Cards /> {/* Supondo que você tenha um componente Cards */}
    </div>
  );
}

export default Home;
